// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var A = require("../Link/A.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var $$Image = require("../Image/Image.bs.js");
var React = require("react");
var Amplitude = require("../../../libs/Amplitude.bs.js");
var TryMeIcon = require("../../svgs/TryMeIcon.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NoPhotoIcon = require("../../icons/NoPhotoIcon.bs.js");
var AngleRightIcon = require("../../icons/AngleRightIcon.bs.js");
var AwsImageHandlerV5 = require("../../../libs/AwsImageHandlerV5.bs.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");
var SearchListingExampleScss = require("./SearchListingExample.scss").default;

var css = SearchListingExampleScss;

function SearchListingExample(props) {
  var exampleListing = props.exampleListing;
  var photo = React.useMemo((function () {
          if (exampleListing !== undefined) {
            return Belt_Array.get(exampleListing.photos, 0);
          }
          
        }), [exampleListing]);
  var tmp;
  if (exampleListing !== undefined) {
    var tmp$1;
    if (photo !== undefined) {
      var photo$1 = Caml_option.valFromOption(photo);
      tmp$1 = JsxPPXReactSupport.createElementWithKey(Curry._1(AwsImageHandlerV5.ImageUrl.toString, photo$1), $$Image.make, {
            className: css.image,
            url: Curry._1(AwsImageHandlerV5.ImageUrl.toString, photo$1),
            fetchWidth: 48,
            fetchHeight: 48,
            alt: exampleListing.title,
            decoding: /* Async */0,
            fetchPriority: /* High */0
          });
    } else {
      tmp$1 = React.createElement(NoPhotoIcon.make, {
            size: "XXL",
            color: "LightGray"
          });
    }
    tmp = React.createElement("div", {
          className: css.exampleContainer
        }, React.createElement(A.Unstyled.make, {
              href: exampleListing.htmlUrl,
              className: css.listingExampleWrapper,
              onClick: (function (param) {
                  if (exampleListing !== undefined) {
                    return Amplitude.HomePageSearch.tryListingExample(exampleListing.id);
                  }
                  
                }),
              children: null
            }, React.createElement("div", undefined, tmp$1), React.createElement("div", {
                  className: css.dataWrapper
                }, React.createElement("div", {
                      className: css.title
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "search-listing-example.listing-title",
                          defaultMessage: "{title}",
                          values: {
                            title: exampleListing.title
                          }
                        })), React.createElement("div", {
                      className: css.detailsWrapper
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "search-listing-example.listing-address",
                          defaultMessage: "{bedrooms} BD in {address}",
                          values: {
                            bedrooms: exampleListing.bedrooms,
                            address: exampleListing.displayAddress
                          }
                        }))), React.createElement(AngleRightIcon.make, {
                  title: "Right Angle Icon",
                  size: "MD",
                  color: "General",
                  className: css.angleRightIcon
                })), React.createElement(TryMeIcon.make, {
              color: "Primary",
              className: css.tryMeIcon
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: css.searchInstructions
            }, React.createElement("div", {
                  className: css.searchInstructionsTitle
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "search-listing-instructions.title",
                      defaultMessage: "HOW IT WORKS?"
                    })), React.createElement("div", {
                  className: css.searchInstructionsDescription
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "search-listing-instructions.description",
                      defaultMessage: "Paste an Airbnb, Booking.com or Vrbo listing link here to find more price options for that specific rental."
                    })), tmp);
}

var make = SearchListingExample;

var $$default = SearchListingExample;

exports.css = css;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* css Not a pure module */
