// @flow
import React from 'react';
import cn from 'classnames';

import * as Amplitude from 'app/rescript/libs/Amplitude.bs.js';
import { buildImageUrlFromJs as buildImageUrl } from 'app/rescript/libs/AwsImageHandlerV5.bs.js';

import css from './TrustBanner.scss';

const TRUST_PARTNERS = [
  {
    url: 'https://www.cardrates.com/news/hichee-can-save-travelers-both-time-and-money/',
    imgHref: 'images/static/welcome/card-rates-black-logo.webp',
    imgWidth: 120,
    imgHeight: 15,
    alt: 'Card Rates',
    colorful: true,
    classNameLink: css.trustPartnerLinkCardRates,
    classNameImage: css.trustPartnerImageCardRates,
  },
  {
    url: 'https://cyberguy.com/travel/how-to-compare-vacation-rental-home-prices/',
    imgHref: 'images/static/welcome/ciber_guy_logo_homepage.webp',
    imgWidth: 50,
    imgHeight: 40,
    alt: 'Cyber Guy',
    colorful: true,
  },
  {
    url:
      'https://blog.hichee.com/want-to-really-get-away-heres-some-expert-advice-for-digital-nomad-accommodations/',
    imgHref: 'images/static/welcome/forbes_logo_homepage.webp',
    imgWidth: 52,
    imgHeight: 14,
    alt: 'Forbes',
  },
  {
    url: 'https://www.washingtonpost.com/travel/tips/airbnb-vrbo-rental-scams/',
    imgHref: 'images/static/homepage-wp-vector-logo.webp',
    imgWidth: 90,
    imgHeight: 13,
    alt: 'Washington Post',
  },
  {
    url: 'https://searanchabalonebay.com/vacation-tips/save-money/',
    imgHref: 'images/static/welcome/sea_ranch_logo_homepage.webp',
    imgWidth: 60,
    imgHeight: 60,
    alt: 'Sea Ranch Abalone Bay',
    colorful: true,
  },
  {
    url:
      'https://www.elliott.org/answers/how-to-rent-vacation-home-ultimate-guide/#h-how-do-i-find-and-rent-a-vacation-home',
    imgHref: 'images/static/elliott-report.webp',
    imgWidth: 70,
    imgHeight: 34,
    alt: 'Elliott Report',
  },
  {
    url: 'https://www.aarp.org/money/budgeting-saving/info-2023/99-great-ways-to-save.html',
    imgHref: 'images/static/welcome/arrb_logo_homepage.webp',
    imgWidth: 240,
    imgHeight: 60,
    alt: 'AARP',
    colorful: true,
  },
  {
    url: 'https://www.foxnews.com/tech/how-compare-vacation-rental-home-prices',
    imgHref: 'images/static/welcome/fox_news_logo_homepage.webp',
    imgWidth: 60,
    imgHeight: 60,
    alt: 'Fox News',
  },
];

const TrustBanner = () => {
  const handlePartnerClick = (name: string) => {
    Amplitude.logEvent('Featured tile clicked', {
      value: name,
    });
  };

  return (
    <div id="trust-banner" className={cn(css.sectionWrapperTrustPartners, css.column)}>
      <div className={css.trustPartnersWrapper}>
        {TRUST_PARTNERS.map(partner => (
          <a
            key={partner.url}
            onClick={() => handlePartnerClick(partner.alt)}
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
            className={partner.classNameLink ? partner.classNameLink : css.trustPartnerLink}
          >
            <picture>
              <source
                srcSet={`
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth,
                      height: partner.imgHeight,
                    })},
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 2,
                      height: partner.imgHeight * 2,
                    })} 2x,
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 3,
                      height: partner.imgHeight * 3,
                    })} 3x,
                  `}
              />
              <img
                className={partner.classNameImage ? partner.classNameImage : css.trustPartnerImage}
                src={buildImageUrl({ url: partner.imgHref })}
                alt={partner.alt}
                loading="lazy"
              />
            </picture>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TrustBanner;
